<template>
  <div>
    <b-row>
      <b-col
        sm="12"
        md="8"
        lg="8"
      >
        <b-card
          no-body
        >
          <div class="pt-2 pl-3">
            <b-form-group>
              <b-form-radio-group
                v-model="currentStatus"
                :options="statusOptions"
                switches
              />
            </b-form-group>
          </div>
        </b-card>
      </b-col>
      <b-col
        sm="12"
        md="4"
        lg="4"
      >
        <div class="card">
          <b-input-group
            class="input-group-merge p-1 mt-0"
            size="sm"
          >
            <b-form-input
              v-model="currentTicket"
              size="sm"
              placeholder="Entrer le ticket pour rechercher"
            />
            <b-input-group-append is-text>
              <span
                class="cursor"
                @click="getTransactionById"
              >
                <feather-icon icon="SearchIcon" />
              </span>
            </b-input-group-append>
            <b-input-group-append is-text>
              <span
                class="cursor"
                @click="clearSearch"
              >
                <feather-icon icon="XIcon" />
              </span>
            </b-input-group-append>
          </b-input-group>
        </div>
      </b-col>
    </b-row>
    <div
      v-if="loadSearch"
      class="text-center"
    >
      <b-spinner variant="primary" />
      <div class="d-flex justify-content-center">
        <p>Recherche en cours</p>
      </div>
    </div>
    <div
      v-if="loaded"
      class="text-center"
    >
      <b-spinner variant="primary" />
    </div>
    <div v-if="!loaded && items.length > 0">
      <div
        v-for="(item, index) in items"
        :key="index"
        class="mb-2"
      >
        <b-card
          :class="{'d-flex flex-row':true}"
          no-body
        >
          <div class="d-flex flex-grow-1 min-width-zero">
            <div class="card-body p-fixed d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
              <p class="mb-0 font-weight-bold text-primary w-15 w-sm-100">
                {{ item.ticket }}
              </p>
              <p class="mb-0 text-small w-10 w-sm-100">
                {{ item.created_at | moment("DD/MM/YY HH:mm") }}
              </p>
              <p class="mb-0 text-small w-15 w-sm-100">
                <b-icon
                  v-if="item.sentCurrency.ismobile"
                  class="text-danger"
                  icon="phone"
                  font-scale="1"
                />
                {{ item.senderAddress !== null ? truncate(item.senderAddress) : 'Portefeuille' }}
              </p>
              <p class="mb-0 text-small w-20 w-sm-100">
                <b-icon
                  class="text-danger"
                  icon="arrow-up"
                  font-scale="1"
                />
                {{ item.sentCurrency.sigle + ' ( '+ item.realSentValue.toFixed(2) + ' )' }}
              </p>
              <p class="mb-0 text-small w-15 w-sm-100">
                <b-icon
                  class="text-success"
                  icon="arrow-down"
                  font-scale="1"
                />
                {{ item.receivedCurrency.sigle }} ( {{ realReceivedValue(item) }} )
              </p>
              <!-- <p class="mb-0 text-small w-15 w-sm-100">
                    {{ item.email }}
                  </p> -->
              <p
                v-b-tooltip.hover
                class="mb-0 text-small w-10 w-sm-100"
                :title="item.receiverAddress"
              >
                <b-icon
                  v-if="item.receivedCurrency.ismobile"
                  class="text-success"
                  icon="phone"
                  font-scale="1"
                />
                <b-icon
                  v-else-if="item.receivedCurrency.dev_code === 'VISA'"
                  class="text-success"
                  icon="credit-card"
                  font-scale="1"
                />
                <b-icon
                  v-else
                  class="text-success"
                  icon="arrow-down"
                  font-scale="1"
                />
                {{ truncate(item.receiverAddress) }}
              </p>
              <p class="mb-0 text-small w-10 w-sm-100">
                {{ item.updated_at | moment("DD/MM/YY HH:mm") }}
              </p>
              <p
                v-if="searchTransaction"
                class="mb-0 text-small w-10 w-sm-100"
              >
                <b-badge
                  variant="primary"
                  pill
                >
                  {{ getStateName(searchTransaction.state) }}
                </b-badge>
              </p>
            </div>
            <div class="custom-control custom-checkbox pl-1 align-self-center pt-1 pr-1">
              <div class="mb-1 text-small w-5 w-sm-100">
                <b-dropdown
                  variant="link"
                  dropright
                  toggle-class="text-decoration-none"
                  no-caret
                >
                  <template #button-content>
                    <b-icon
                      class="text-primary"
                      icon="three-dots-vertical"
                      font-scale="1.2"
                    />
                  </template>
                  <b-dropdown-item @click="openModal(item)">
                    <span class="ml-1">Détails</span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="openGlobalStateChangeModal(item)">
                    <span class="ml-1">Etat Global</span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </div>
    <div v-if="!loaded && !loadSearch && items.length === 0">
      <b-card
        :class="{'d-flex flex-row':true}"
        no-body
      >
        <div class="pl-2 d-flex flex-grow-1 min-width-zero">
          <div class="card-body align-self-center d-flex flex-column flex-lg-row justify-content-between min-width-zero align-items-lg-center">
            <div class="text-center">
              <p class="mb-0 font-weight-bold text-primary w-100 w-sm-100">
                <span v-if="currentStatus === 'CREATED'">Aucune transaction initiée..</span>
                <span v-else-if="currentStatus === 'PENDING'">Aucune transaction en traitement.</span>
                <span v-else-if="currentStatus === 'WAITING'">Aucune transaction en attente de validation.</span>
                <span v-else-if="currentStatus === 'SUCCESSFUL'">Aucune transaction validé.</span>
                <span v-else-if="currentStatus === 'REJECTED'">Aucune transaction rejeté.</span>
                <span v-else>Aucune de vos transactions n'est reliée a ce ticket.</span>
              </p>
            </div>
          </div>
        </div>
      </b-card>
    </div>
    <div
      v-if="!loaded && items.length > 0"
      class="mt-2 mb-2"
    >
      <div class="float-left">
        <b-form-select
          v-model="perPage"
          :options="pageOptions"
        />
      </div>

      <b-pagination
        v-model="currentPage"
        :total-rows="totalItems"
        :per-page="perPage"
        first-number
        last-number
        align="right"
      />
    </div>

    <b-modal
      v-if="showFirstManualModal"
      v-model="showFirstManualModal"
      centered
      hide-footer
      title="Transaction Manuelle"
    >
      <div>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Ticket">
              <b-form-input
                v-model="currentTransaction.ticket"
                readonly
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Montant envoyé">
              <b-form-input
                v-model="currentTransaction.realSentValue"
                readonly
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Status">
              <b-form-select
                v-model="$v.currentState.$model"
                :options="stateOptions"
                :class="{
                  'is-invalid': $v.currentState.$error,
                }"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="currentState === 'FAILED'"
            cols="12"
          >
            <b-form-group label="Motif">
              <b-form-textarea
                v-model="$v.motif.$model"
                :class="{
                  'is-invalid': $v.motif.$error,
                }"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <div>
          <div
            v-if="firstProcess"
            class="text-center mt-2 mb-1"
          >
            <div>
              <b-spinner variant="primary" />
              <p>Confirmation en cours ...</p>
            </div>
          </div>
          <b-row>
            <b-col>
              <b-button
                class="w-100"
                variant="outline-primary"
                @click="tryToCloseFisrtExchange"
              >Confirmer la réception</b-button>
            </b-col>
          </b-row>
        </div>
        <div v-if="currentTransaction.sentCurrency.is_crpto">
          <b-row>
            <b-col>
              <a
                :href="currentTransaction.crypto_status_url"
                target="_blank"
                class="btn btn-outline-primary w-100"
              >Voir la transaction</a>
            </b-col>
            <b-col v-if="currentTransaction.sentState === 'UNRESOLVED'">
              <b-button
                class="w-100"
                variant="outline-primary"
                @click="redirectOnClose(currentTransaction.ticket)"
              >Clôturer</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-if="showSecondManualModal"
      v-model="showSecondManualModal"
      centered
      hide-footer
      title="Clôture Manuelle"
    >
      <div>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Ticket">
              <b-form-input
                v-model="currentTransaction.ticket"
                readonly
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Montant à recevoir">
              <b-form-input
                v-model="currentTransaction.receivedValue"
                readonly
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Message">
              <b-form-textarea
                v-model="$v.message.$model"
                :class="{
                  'is-invalid': $v.message.$error,
                }"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <div>
          <div
            v-if="process"
            class="text-center mt-2 mb-1"
          >
            <div>
              <b-spinner variant="primary" />
              <p>Clôture en cours ...</p>
            </div>
          </div>
          <b-row>
            <b-col>
              <b-button
                class="w-100"
                variant="outline-primary"
                @click="tryToCloseExchange"
              >Valider</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-if="showCashinModal"
      v-model="showCashinModal"
      centered
      hide-footer
      title="CashIN Manuel"
    >
      <span class="text-primary">
        {{ currentTransaction.ticket }}
      </span>
      <div>
        <b-row>
          <b-col cols="12">
            <b-form-group label="N° Receveur">
              <b-form-input
                v-model="currentTransaction.receiverAddress"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Moyen de Paiement">
              <b-form-input
                v-model="currentTransaction.receivedCurrency.libelle"
                readonly
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Montant">
              <b-form-input
                v-model="currentTransaction.receivedValue"
                readonly
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group label="Message">
              <b-form-input
                v-model="$v.cashinMemo.$model"
                type="text"
                :class="{
                  'is-invalid': $v.cashinMemo.$error,
                }"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <div>
          <div
            v-if="cashInProcess"
            class="text-center mt-2 mb-1"
          >
            <div>
              <b-spinner variant="primary" />
              <p>Cashin en cours ...</p>
            </div>
          </div>
          <b-row>
            <b-col>
              <b-button
                class="w-100"
                variant="outline-primary"
                @click="tryToMakeCashin"
              >Valider</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-if="showDetailsModal"
      v-model="showDetailsModal"
      centered
      hide-footer
      title="Détails"
    >
      <div>
        <b-tabs
          justified
          content-class="mt-3"
        >
          <b-tab
            title="Echange"
            active
          >
            <p class="text-primary">
              TICKET :  {{ currentTransaction.ticket }}
            </p>
            <b-row>
              <b-col sm="12">
                <b-table-simple
                  caption-top
                  responsive
                  hover
                >
                  <caption>Envoyé</caption>
                  <b-tr class="w-100">
                    <b-td>
                      Je donne
                    </b-td>
                    <b-td>
                      <img
                        :src="currentTransaction.sentCurrency.icones"
                        class=""
                        width="20"
                        height="20"
                      >
                      {{ currentTransaction.sentDevCode }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Montant à echanger
                    </b-td>
                    <b-td>
                      {{ currentTransaction.realSentValue.toFixed(2) }}
                    </b-td>
                  </b-tr>
                  <b-tr v-if="!currentTransaction.sentCurrency.is_crypto">
                    <b-td>
                      Adresse d'envoi
                    </b-td>
                    <b-td>
                      {{ currentTransaction.senderAddress }}
                    </b-td>
                  </b-tr>
                  <b-tr v-if="currentTransaction.sentDevCode === 'WAVE_CI' || currentTransaction.sentDevCode === 'WAVE_SENEGAL'">
                    <b-td>
                      Expéditeur
                    </b-td>
                    <b-td>
                      {{ currentTransaction.senderAddressName }}
                    </b-td>
                  </b-tr>
                  <b-tr v-if="currentTransaction.sentDevCode === 'WAVE_CI' || currentTransaction.sentDevCode === 'WAVE_SENEGAL'">
                    <b-td>
                      Adresse de paiment
                    </b-td>
                    <b-td>
                      {{ currentTransaction.sentRecipientAddress }}
                    </b-td>
                  </b-tr>
                  <b-tr v-if="currentTransaction.sentDevCode === 'WAVE_CI' || currentTransaction.sentDevCode === 'WAVE_SENEGAL'">
                    <b-td>+
                      Capture d'écran
                    </b-td>
                    <b-td v-if="currentTransaction.proof.length > 0">
                      <a
                        :href="currentTransaction.proof[0].name"
                        target="_blank"
                        rel="noopener noreferrer"
                      >Voir</a>
                    </b-td>
                    <b-td v-else>
                      Fichier indisponible
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Status
                    </b-td>
                    <b-td>
                      {{ currentTransaction.sentState }}
                    </b-td>
                  </b-tr>
                  <b-tr v-if="currentTransaction.sentCurrency.ismobile && currentTransaction.sentCurrency.is_auto && (currentTransaction.sentState === 'PENDING' || currentTransaction.sentState === 'INITIATED') && (currentTransaction.state === 'WAITING' || currentTransaction.state === 'PENDING')">
                    <b-td>
                      Vérifier
                    </b-td>
                    <b-td>
                      <b-button
                        variant="outline-primary"
                        class="w-100"
                        @click="getCurrentTicket(currentTransaction.id)"
                      >
                        Check
                      </b-button>
                    </b-td>
                  </b-tr>
                </b-table-simple>
              </b-col>
              <b-col cols="12">
                <div
                  v-if="checkState"
                  class="text-center mt-2 mb-1"
                >
                  <div>
                    <b-spinner variant="primary" />
                    <p>Vérification en cours ...</p>
                  </div>
                </div>
              </b-col>
              <b-col sm="12">
                <b-table-simple
                  caption-top
                  responsive
                  hover
                >
                  <caption>A recevoir</caption>
                  <b-tr class="w-100">
                    <b-td>
                      Je recois
                    </b-td>
                    <b-td>
                      <img
                        :src="currentTransaction.receivedCurrency.icones"
                        class=""
                        width="20"
                        height="20"
                      >
                      {{ currentTransaction.receivedDevCode }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Montant à recevoir
                    </b-td>
                    <b-td>
                      {{ realReceivedValue(currentTransaction) }}
                    </b-td>
                  </b-tr>
                  <b-tr v-if="currentTransaction.receivedDevCode !== 'VISA'">
                    <b-td>
                      Adresse de reception
                    </b-td>
                    <b-td>
                      <span
                        v-if="!currentTransaction.receivedCurrency.is_auto || (currentTransaction.receivedCurrency.is_crypto && currentTransaction.receivedCurrency.sale_auto)"
                        id="receiveAddress"
                        v-b-tooltip.hover.top="'Cliquer pour copier!'"
                        class="cursor text-truncate"
                        @click="onCopy(currentTransaction.receiverAddress, 'receiveAddress')"
                      >
                        <b-icon
                          class="text-primary"
                          icon="clipboard"
                          font-scale="1"
                        />
                        {{ currentTransaction.receiverAddress }}
                      </span>
                      <span
                        v-else
                      >
                        {{ currentTransaction.receiverAddress }}
                      </span>
                    </b-td>
                  </b-tr>
                  <b-tr v-if="currentTransaction.receivedDevCode === 'WAVE_CI' || currentTransaction.receivedDevCode === 'WAVE_SENEGAL' || currentTransaction.receivedDevCode === 'VISA'">
                    <b-td>
                      Destinataire
                    </b-td>
                    <b-td>
                      {{ currentTransaction.receiverAddressName }}
                    </b-td>
                  </b-tr>
                  <b-tr v-if="currentTransaction.receivedDevCode === 'VISA'">
                    <b-td>
                      ID Carte
                    </b-td>
                    <b-td>
                      <span
                        id="visaCardId"
                        v-b-tooltip.hover.top="'Cliquer pour copier!'"
                        class="cursor text-truncate"
                        @click="onCopy(currentTransaction.visaCardId, 'visaCardId')"
                      >
                        <b-icon
                          class="text-primary"
                          icon="clipboard"
                          font-scale="1"
                        />
                        {{ currentTransaction.visaCardId }}
                      </span>
                    </b-td>
                  </b-tr>
                  <b-tr v-if="currentTransaction.receivedDevCode === 'VISA'">
                    <b-td>
                      N° Carte
                    </b-td>
                    <b-td>
                      <span
                        id="visaLastDigit"
                        v-b-tooltip.hover.top="'Cliquer pour copier!'"
                        class="cursor text-truncate"
                        @click="onCopy(currentTransaction.visaLastDigit, 'visaLastDigit')"
                      >
                        <b-icon
                          class="text-primary"
                          icon="clipboard"
                          font-scale="1"
                        />
                        {{ currentTransaction.visaLastDigit }}
                      </span>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Status
                    </b-td>
                    <b-td>
                      {{ currentTransaction.receivedState }}
                    </b-td>
                  </b-tr>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab
            title="Client"
            title-item-class="text-uppercase"
          >
            <b-row>
              <b-col sm="12">
                <b-table-simple
                  responsive
                  hover
                >
                  <b-tr>
                    <th>
                      Nom
                    </th>
                    <b-td>
                      {{ currentTransaction.owner.name + ' ' + currentTransaction.owner.surname }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Sexe
                    </b-td>
                    <b-td>
                      {{ currentTransaction.owner.sex }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Localisation
                    </b-td>
                    <b-td>
                      {{ currentTransaction.owner.country.toUpperCase() + ' / ' + currentTransaction.owner.city + ' / ' + currentTransaction.owner.address }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Email
                    </b-td>
                    <b-td>
                      {{ currentTransaction.owner.email }}
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>
                      Téléphone
                    </b-td>
                    <b-td>
                      {{ currentTransaction.owner.phone }}
                    </b-td>
                  </b-tr>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
        <hr>
        <div>
          <b-row>
            <b-col v-if="getAutomateState(currentTransaction.sentDevCode)">
              <b-button
                v-if="currentTransaction.sentState === 'WAITING'"
                class="w-100"
                variant="outline-primary"
                @click="openFirstModal"
              >Traitement manuel</b-button>
            </b-col>
            <b-col v-if="currentTransaction.sentState === 'SUCCESSFUL' && (!currentTransaction.receivedCurrency.is_auto) && currentTransaction.receivedState !== 'SUCCESSFUL'">
              <b-button
                class="w-100"
                variant="outline-primary"
                @click="openSecondModal"
              >Clôture manuelle</b-button>
            </b-col>
          </b-row>
        </div>
        <div v-if="currentTransaction.sentCurrency.is_crypto">
          <b-row>
            <b-col>
              <a
                :href="currentTransaction.crypto_status_url"
                target="_blank"
                class="btn btn-outline-primary w-100"
              >Voir la transaction</a>
            </b-col>
            <b-col v-if="currentTransaction.sentState === 'UNRESOLVED'">
              <b-button
                class="w-100"
                variant="outline-primary"
                @click="redirectOnClose(currentTransaction.ticket)"
              >Clôturer</b-button>
            </b-col>
          </b-row>
        </div>
        <div
          v-if="
            currentTransaction.receivedCurrency.is_auto
              && currentTransaction.receivedState === 'FAILED'"
          class="p-1"
        >
          <b-row>
            <b-col v-if="currentStatus === 'WAITING'">
              <b-button
                class="w-100"
                variant="outline-primary"
                @click="openCashinModal"
              >Cashin Manuel</b-button>
            </b-col>
          </b-row>
        </div>
      </div>
    </b-modal>
    <b-modal
      v-if="showGlobalStateChangeModal"
      v-model="showGlobalStateChangeModal"
      centered
      hide-footer
      :no-close-on-esc="globalProcess"
      :busy="globalProcess"
      :no-close-on-backdrop="globalProcess"
      title="Changement de l'état global de la transaction"
    >
      <h4>Ticket : {{ currentTransaction.ticket }}</h4>
      <div>
        <v-select
          v-model="currentCurrency"
          :clearable="true"
          label="title"
          :options="currenciesOptions"
        >
          <template #header>
            <div class="text-primary">
              Moyen de réception !!!</div>
          </template>
          <template v-slot:option="option">
            <img
              :src="option.src"
              class="mr-1 float-left"
              width="45"
              height="45"
            >
            <span>{{ option.data.libelle }}</span> <br>
            <span>{{ option.data.buy }} FCFA </span>
          </template>
        </v-select>

        <b-form-group label="Adresse de réception">
          <b-form-input
            v-model="currentTransaction.receiverAddress"
            type="text"
          />
        </b-form-group>
        <b-form-group label="Montant">
          <b-form-input
            v-model="currentTransaction.receivedValue"
            type="number"
          />
        </b-form-group>
        <b-form-group label="Etat Global">
          <b-form-select
            v-model="globalStatus"
            :options="globaStatusOptions"
          />
        </b-form-group>
        <div
          v-if="globalProcess"
          class="text-center mt-2 mb-1"
        >
          <div>
            <b-spinner variant="primary" />
            <p>Clôture en cours ...</p>
          </div>
        </div>
        <b-button
          class="w-100"
          variant="outline-primary"
          :disabled="globalProcess"
          @click="tryToChangeState"
        >Clôturer</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required,
  requiredIf,
} from 'vuelidate/lib/validators'
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      items: [],
      allCurrencies: [],
      manualCurrencies: [],
      statusOptions: [
        { value: 'CREATED', text: 'CREEE' },
        { value: 'PENDING', text: 'EN APPROBATION' },
        { value: 'WAITING', text: 'EN TRAITEMENT' },
        { value: 'FAILED', text: 'ECHEC' },
        { value: 'SUCCESSFUL', text: 'VALIDE' },
      ],
      searchTransaction: null,
      globalStatus: null,
      loadSearch: false,
      currentTicket: null,
      currentCurrency: null,
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 5,
      checkState: false,
      loaded: false,
      totalItems: 0,
      currentStatus: 'WAITING',
      currentTransaction: null,
      showDetailsModal: false,
      showFirstManualModal: false,
      showSecondManualModal: false,
      showCashinModal: false,
      showGlobalStateChangeModal: false,
      motif: null,
      currentState: null,
      cashinMemo: null,
      message: null,
      globaStatusOptions: [
        { value: null, text: 'Choisir' },
        { value: 'WAITING', text: 'TRAITEMENT' },
        { value: 'SUCCESSFUL', text: 'SUCCES' },
        { value: 'FAILED', text: 'ECHEC' },
      ],
      stateOptions: [
        { value: null, text: 'Choisir' },
        { value: 'SUCCESSFUL', text: 'Reçu' },
        { value: 'FAILED', text: 'Non reçu' },
      ],
    }
  },
  computed: {
    ...mapGetters({
      process: 'closeSecondManualProcess',
      success: 'closeSecondManualSuccess',
      error: 'closeSecondManualError',

      firstProcess: 'closeFirstManualProcess',
      firstSuccess: 'closeFirstManualSuccess',
      firstError: 'closeFirstManualError',

      cashInProcess: 'manualCashinProcess',
      cashInSuccess: 'manualCashinSuccess',
      cashInError: 'manualCashinError',

      globalProcess: 'changeGlobalStateProcess',
      globalSuccess: 'changeGlobalStateSuccess',
      globalError: 'changeGlobalStateError',
    }),
    currenciesOptions() {
      const data = []
      if (this.allCurrencies.length > 0) {
        this.allCurrencies.forEach(item => {
          const current = {
            id: item.id,
            title: item.libelle,
            code: item.dev_code,
            data: item,
            src: item.icones,
          }
          data.push(current)
        })
      }
      return data
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.getAllTransactions()
        this.getAllSystemsCurrencies()
      },
    },
    currentState(val) {
      if (val && val === 'SUCCESSFUL') {
        this.motif = null
        this.$nextTick(() => {
          this.$v.motif.$reset()
        })
      }
    },
    currentPage(val) {
      if (val) {
        this.getAllTransactions()
      }
    },
    currentStatus(val) {
      if (val) {
        this.currentPage = 1
        this.currentTicket = null
        this.searchTransaction = null
        this.getAllTransactions()
      }
    },
    perPage(val) {
      if (val) {
        this.getAllTransactions()
      }
    },
    success(val) {
      if (val) {
        this.currentStatus = 'SUCCESSFUL'
        this.closeFirstModal()
        this.closeSecondModal()
        this.closeModal()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Clôture manuelle',
            icon: 'CheckCircleIcon',
            text: 'Transaction clôturée avec succès.',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Clôture manuelle',
            icon: 'XCircleIcon',
            text: 'Transaction non effectuée.',
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    firstSuccess(val) {
      if (val) {
        this.currentStatus = val.data.data.state
        this.closeFirstModal()
        this.closeSecondModal()
        this.closeModal()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Confirmation de reception',
            icon: 'CheckCircleIcon',
            text: 'Confirmer avec succès.',
            variant: 'success',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    firstError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Confirmation de reception',
            icon: 'XCircleIcon',
            text: 'Transaction non effectuée.',
            variant: 'danger',
          },
        },
        {
          position: 'top-center',
        })
      }
    },
    cashInSuccess(val) {
      if (val) {
        const res = val.data
        if (val.valid === 1) {
          this.currentStatus = res.state
          this.closeFirstModal()
          this.closeSecondModal()
          this.closeCashinModal()
          this.closeModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Envoie d'argent Manuel",
              icon: 'CheckCircleIcon',
              text: 'Envoyé avec succès.',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Envoie d'argent Manuel",
              icon: 'CheckCircleIcon',
              text: "Une erreur est survenue, veuillez recharger votre page afin de vérifier l'état global de la transaction.",
              variant: 'error',
            },
          },
          {
            position: 'top-center',
          })
        }
      }
    },
    cashInError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Envoie d'argent Manuel",
            icon: 'XCircleIcon',
            text: 'Une erreur est survenue.',
            variant: 'danger',
          },
        })
      }
    },
    globalSuccess(val) {
      if (val) {
        const res = val.data
        if (val.valid === 1) {
          this.currentStatus = res.state
          this.closeGlobalStateChangeModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Envoie d'argent Manuel",
              icon: 'CheckCircleIcon',
              text: 'Envoyé avec succès.',
              variant: 'success',
            },
          },
          {
            position: 'top-center',
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Changement Global d'état",
              icon: 'CheckCircleIcon',
              text: "Une erreur est survenue, veuillez recharger votre page afin de vérifier l'état global de la transaction.",
              variant: 'error',
            },
          },
          {
            position: 'top-center',
          })
        }
      }
    },
    globalError(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Changement Global d'état",
            icon: 'XCircleIcon',
            text: 'Une erreur est survenue.',
            variant: 'danger',
          },
        })
      }
    },
  },
  validations: {
    currentState: {
      required,
    },
    cashinMemo: {
      required,
    },
    motif: {
      // eslint-disable-next-line func-names
      required: requiredIf(function () {
        if (this.currentState === 'FAILED') {
          return true
        }
        return false
      }),
    },
    message: {
      required,
    },
  },
  mounted() {
    this.getAllTransactions()
    this.getAllCurrencies()
  },
  methods: {
    ...mapActions(['closeSecondManualTransaction', 'closeFirstManualTransaction', 'makeManualCashin', 'changeTransactionGlobalState']),
    truncate(item) {
      const length = 10
      return item.length <= length ? item : `${item.substring(0, length)}...`
    },
    tryToChangeState() {
      this.changeTransactionGlobalState({
        receiverAddress: this.currentTransaction.receiverAddress,
        receivedDevCode: this.currentCurrency.code,
        state: this.globalStatus,
        value: this.currentTransaction.receivedValue,
        transactionId: this.currentTransaction.id,
      })
    },
    async getAllSystemsCurrencies() {
      this.allCurrencies = []
      this.allCurrencies = await
      this.$http
        .get('/currencies/all', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          const { data } = res.data
          return data
        })
        .catch(() => [])
    },
    openGlobalStateChangeModal(item) {
      this.currentTransaction = item
      // eslint-disable-next-line prefer-destructuring
      this.currentCurrency = this.currenciesOptions.filter(elm => elm.code === item.receivedDevCode)[0]
      this.showGlobalStateChangeModal = true
    },
    closeGlobalStateChangeModal() {
      this.currentTransaction = null
      this.showGlobalStateChangeModal = false
    },
    realReceivedValue(item) {
      if (item.receivedCurrency.dev_code === 'PAYEER_EURO' || item.receivedCurrency.dev_code === 'PAYEER_USD') {
        return (item.receivedValue - (item.receivedValue * item.receivedCurrency.partner_fees_in)).toFixed(2)
      }
      return item.receivedValue.toFixed(2)
    },
    async getCurrentTicket(item) {
      this.checkState = true
      await this
        .$http
        .get(`/check-merchant-payment-status-in-touch/${item}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          this.checkState = false
          this.currentStatus = res.data.data.state
          if (res.data.data.sentState === 'SUCCESSFUL') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Vérification de la transaction',
                text: 'Paiment mobile reçu avec succès.',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            })
          } else if (res.data.data.sentState === 'PENDING') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Vérification de la transaction',
                text: 'Paiment mobile en attente réception / confirmation.',
                icon: 'CheckCircleIcon',
                variant: 'warning',
              },
            })
          } else if (res.data.data.sentState === 'FAILED') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Vérification de la transaction',
                text: 'Transaction supendue.',
                icon: 'XCircleIcon',
                variant: 'danger',
              },
            })
          }
          this.closeModal()
          this.getAllTransactions()
        })
        .catch(() => {
          this.checkState = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Vérification de la transaction',
              text: 'Une erreur est survenue, veuillez réessayer',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
    getStateName(item) {
      let name = null
      switch (item) {
        case 'SUCCESSFUL':
          name = 'VALIDE'
          break
        case 'FAILED':
          name = 'ECHEC'
          break
        case 'WAITING':
          name = 'EN TRAITEMENT'
          break
        case 'PENDING':
          name = 'EN APPROBATION'
          break
        case 'CREATED':
          name = 'CREE'
          break
        case 'INITIATED':
          name = 'ECHEC'
          break
        case 'MANUAL_WAITING':
          name = 'TRAITEMENT MANUEL'
          break
        case 'NOTFOUND':
          name = 'NON TROUVE'
          break
        default:
          break
      }
      return name
    },
    async getTransactionById() {
      this.loadSearch = true
      this.currentStatus = null
      this.items = []
      this.searchTransaction = await this.$http
        .get(`/find-all-by-ticket/${this.currentTicket}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          this.loadSearch = false
          this.items.push(res.data)
          return res.data
        })
        .catch(() => {
          this.loadSearch = false
          return null
        })
    },
    clearSearch() {
      this.currentTicket = null
      if (this.currentStatus) return
      this.currentStatus = 'WAITING'
    },
    onCopy(item, id) {
      const container = document.getElementById(`${id}`)
      this.$copyText(item, container).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copie',
            icon: 'CheckSquareIcon',
            text: `${item}`,
            variant: 'success',
          },
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copie',
            icon: 'XCircleIcon',
            text: 'Copie échouée.',
            variant: 'danger',
          },
        })
      })
    },
    tryToCloseExchange() {
      this.$v.message.$touch()
      if (this.$v.message.$anyError) {
        return
      }
      this.closeSecondManualTransaction({
        id: this.currentTransaction.id,
        ticket: this.currentTransaction.ticket,
        message: this.message,
        value: this.currentTransaction.receivedValue,
        transactionId: null,
      })
    },
    tryToCloseFisrtExchange() {
      this.$v.currentState.$touch()
      this.$v.motif.$touch()
      if (this.$v.currentState.$anyError || this.$v.motif.$anyError) {
        return
      }
      this.closeFirstManualTransaction({
        id: this.currentTransaction.id,
        ticket: this.currentTransaction.ticket,
        motif: this.motif,
        sentValue: this.currentTransaction.realSentValue,
        receivedValue: this.currentTransaction.receivedValue,
        state: this.currentState,
      })
    },
    tryToMakeCashin() {
      this.$v.cashinMemo.$touch()
      if (this.$v.cashinMemo.$anyError) {
        return
      }
      this.makeManualCashin({
        receiverAddress: this.currentTransaction.receiverAddress,
        devCode: this.currentTransaction.receivedDevCode,
        memo: this.cashinMemo,
        value: this.currentTransaction.receivedValue,
        transactionId: this.currentTransaction.id,
      })
    },
    openSecondModal() {
      this.showSecondManualModal = true
    },
    closeSecondModal() {
      this.showSecondManualModal = false
      this.message = null
      this.$nextTick(() => {
        this.$v.message.$reset()
      })
    },
    openCashinModal() {
      this.showCashinModal = true
    },
    closeCashinModal() {
      this.showCashinModal = false
    },
    openFirstModal() {
      this.showFirstManualModal = true
    },
    closeFirstModal() {
      this.showFirstManualModal = false
      this.currentState = null
      this.motif = null
      this.$nextTick(() => {
        this.$v.currentState.$reset()
        this.$v.motif.$reset()
      })
    },
    redirectOnClose(item) {
      this.$router.push(`/admin/settings/close-transaction/${item}`)
    },
    async getAllCurrencies() {
      this.manualCurrencies = await
      this.$http
        .get('/currencies/all', {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => res.data.data.filter(item => item.is_auto === false))
        .catch(() => [])
    },
    getAutomateState(code) {
      const data = this.manualCurrencies
      const manualBuyCurrencies = data.filter(item => item.buy_auto === false)
      const res = manualBuyCurrencies.findIndex(item => item.dev_code === code)
      if (res < 0) {
        return false
      }
      return true
    },
    openModal(item) {
      this.currentTransaction = item
      this.showDetailsModal = true
    },
    closeModal() {
      this.currentTransaction = null
      this.showDetailsModal = false
    },
    async getAllTransactions() {
      this.loaded = true
      this.items = []
      this.items = await
      this.$http
        .get(`/find-all-by-state/${this.currentStatus}/${this.perPage}?page=${this.currentPage}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.currentToken}`,
          },
        })
        .then(res => {
          const { data } = res
          this.loaded = false
          this.totalItems = data.meta.total
          return data.data
        })
        .catch(() => {
          this.loaded = false
          return []
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.cursor {
    cursor: pointer;
  }
</style>
